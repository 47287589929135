import React, { FC, useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "../../hooks";

import "./assets/scss/style.scss";

interface AppCreditsButtonProps {
    conferenceCredits: any;
    className?: string;
}

const labelScope = "event.list";

export const AppCreditsButton: FC<AppCreditsButtonProps> = ({
    conferenceCredits,
    className,
}) => {
    const { t } = useTranslation();
    const [showPopover, setShowPopover] = useState(false);
    const popoverRef = useRef<HTMLDivElement | null>(null);

    const totalPoints = conferenceCredits
        .reduce((acc, credit) => acc + parseFloat(credit.creditPoint), 0)
        .toFixed(1);

    const renderContent = () => {
        return (
            <div className="credit-dropdown--content">
                <div className="credit-dropdown--content--header">
                    <span className="credit-dropdown--content--header--label">
                        {t(`${labelScope}:button.credits`)}
                    </span>
                </div>

                <div className="credit-dropdown--content--list">
                    <div className="credit-column">
                        {conferenceCredits.map((credit) => (
                            <div key={credit.id} className="credit-category">
                                {credit.creditCategory.name}
                            </div>
                        ))}
                    </div>
                    <div className="credit-column">
                        {conferenceCredits.map((_, index) => (
                            <div key={index} className="credit-separator">
                                :
                            </div>
                        ))}
                    </div>
                    <div className="credit-column">
                        {conferenceCredits.map((credit) => (
                            <div key={credit.id} className="credit-point">
                                {credit.creditPoint}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target as Node)
        ) {
            setShowPopover(false);
        }
    };

    useEffect(() => {
        if (showPopover) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [showPopover]);

    const renderButton = (id = "") => (
        <Button
            id={id}
            variant="secondary"
            className={`dropdown-toggle credit-button ${
                conferenceCredits.length === 0 ? "noCredits" : ""
            }`}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowPopover(!showPopover);
            }}
        >
            <svg className="credit-button--label" viewBox="0 0 100 100">
                <path
                    id="textPath"
                    d="M 50, 50 m -35, 0 a 35,35 0 1,1 70,0 a 35,35 0 1,1 -70,0"
                    fill="transparent"
                />
                <text className="credit-button--label--text">
                    <textPath href="#textPath" startOffset="25%">
                        {t("common.label:credit.button.label")}
                    </textPath>
                </text>
            </svg>
            <div className="credit-button--points-label">{totalPoints}</div>
        </Button>
    );

    return (
        <div
            className={`credit-button--container ${className}`}
            ref={popoverRef}
        >
            <OverlayTrigger
                trigger="click"
                placement="bottom-end"
                flip
                show={showPopover}
                onToggle={setShowPopover}
                overlay={
                    <Popover
                        id="popover-basic"
                        className="dropdown-popover-action"
                    >
                        {renderContent()}
                    </Popover>
                }
            >
                {renderButton()}
            </OverlayTrigger>
        </div>
    );
};
